import React, {useState} from "react"
import {graphql, StaticQuery} from "gatsby"
import VisibilitySensor from "react-visibility-sensor"

import ContentContainer from '../content-container'
import Button from "../button";

import MainNav from './components/main-nav'
import ToolNav from './components/tool-nav'

import styles from './component.module.less'

export default function(props) {
  const locale = props.locale || 'de';

  const searchRef = React.createRef();

  const [headerVisible, setHeaderVisible] = useState(true);
  const [mobActive, setMobActive] = useState(false);

  const isMinimal = props.minimal === true;

  let classNames = [styles.header];

  if (React.Children.count(props.children) > 0) {
    classNames.push(styles.withContent);
  }

  if (props.sticky === undefined || props.sticky === true) {
    if (!headerVisible) {
      classNames.push(styles.sticky);
    }
  }

  if (isMinimal) {
    classNames.push(styles.min);
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
      <StaticQuery
          query={graphql`
              query {
                  allDatoCmsConfig {
                      nodes {
                          locale
                          pageContact {
                              slug
                          }
                      }
                  }
                  allDatoCmsTemplateTexte {
                      nodes {
                          locale
                          headerSearchLabel
                          headerToolButtonLabel
                          menBackButtonLabel
                          searchOverlayInputPlaceholder
                          searchOverlayButtonLabel
                      }
                  }
              }
          `}
          render={data => {
            const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === locale);
            const config = data.allDatoCmsConfig.nodes.find(t => t.locale === locale);

            return (
                <>
                  <VisibilitySensor
                      active={props.sticky === undefined || (props.sticky && props.sticky === true)}
                      resizeCheck={false}
                      partialVisibility={true}
                      onChange={isVisible => setHeaderVisible(isVisible)}
                  >
                    <header className={`${classNames.join(' ')}${mobActive ? ' ' + styles.mobActive : ''}`}>
                      <div className={styles.navContainer}>
                        <ContentContainer className={styles.inner}>
                          <nav className={styles.nav}>

                            {!isMinimal &&
                                <>
                                  <Button
                                      className={`${styles.toggle}${mobActive ? ' ' + styles.open : ''}`}
                                      onClick={() => setMobActive(!mobActive)}
                                  >
                                    Menu
                                  </Button>

                                  <MainNav
                                      locale={locale}
                                      mobActive={mobActive}
                                      setMobActive={setMobActive}
                                      backButtonLabel={templateStrings.menBackButtonLabel}
                                      className={styles.col}
                                  />
                                </>
                            }

                            <div className={`${styles.col} ${styles.logo}`} data-datocms-noindex={true}>
                              <a href={'/' + locale + '/'}>Golden Gates</a>
                            </div>

                            {!isMinimal &&
                                <ToolNav
                                    locale={locale}
                                    localeLinks={props.localeLinks}
                                    className={styles.col}
                                    mobActive={mobActive}
                                    searchRef={searchRef}
                                    toolButtonLabel={templateStrings.headerToolButtonLabel}
                                    toolButtonUrl={`/${locale}/${config.pageContact.slug}/`}
                                    searchLabel={templateStrings.headerSearchLabel}
                                />
                            }

                          </nav>
                        </ContentContainer>
                      </div>

                      <div className={styles.content}>
                        {props.children}
                      </div>
                    </header>
                  </VisibilitySensor>
                </>
            );
          }}
      />
  );
}
