import React from "react"

import searchPath from '../../../../lib/search-path'

export default (props) => {

  if (!props.config) {
    return null;
  }

  const locale = props.locale || 'de';

  const websiteData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: process.env.GATSBY_WEB_URL,
    potentialAction: {
      '@type': 'SearchAction',
      target: process.env.GATSBY_WEB_URL + searchPath(locale) + '?s={q}',
      'query-input': 'required name=q',
    }
  };

  const organizationData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: props.config.companyName,
    address: {
      '@type': 'PostalAddress',
      addressLocality: props.config.companyAddressCity,
      postalCode: props.config.companyAddressZip,
      streetAddress: props.config.companyAddress,
    },
    telephone: props.config.companyPhone,
    faxNumber: props.config.companyFax,
  };

  return (
      <>
        <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteData) }}
        />

        <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationData) }}
        />
      </>
  );
}
