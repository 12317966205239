import React, {useState} from "react"
import {graphql, StaticQuery} from "gatsby"

import Button from '../../../button'

import styles from './component.module.less'

export default (props) => {

  const locale = props.locale || 'de';

  const [activeMobIndex, setActiveMobIndex] = useState(null);
  const [prepareMobIndex, setPrepareMobIndex] = useState(null);

  const onMobIndexClick = (index) => {
    return () => {
      if (prepareMobIndex !== null) return;

      setPrepareMobIndex(index);

      setTimeout(() => {
        setPrepareMobIndex(null);
        setActiveMobIndex(index);
      }, 325);
    }
  }

  const itemFilter = n => n.locale === locale && n.title !== null && n.link && n.link.slug !== null;

  return (
      <StaticQuery
          query={graphql`
            query {
                allDatoCmsNavigationMain(sort: {fields: position, order: ASC}, filter: {root: {eq: true}}) {
                    nodes {
                        ...nav_main
                        treeChildren {
                            ...nav_main
                        }
                    }
                }
            }
          `}
          render={data => {
            const main = data.allDatoCmsNavigationMain.nodes.filter(itemFilter);

            let listClasses = [];
            if (props.mobActive) {
              listClasses.push(styles.mobAct);
            }
            if (prepareMobIndex != null) {
              listClasses.push(styles.willOpen);
            }
            listClasses = listClasses.length > 0 ? listClasses.join(' ') : null;

            return (
                <div className={`${styles.navMain}${props.className ? ' ' + props.className : ''}`}>
                  {props.mobActive &&
                    <Button
                        className={`${styles.toggle}`}
                        onClick={() => props.setMobActive(false)}
                    >
                      Menu
                    </Button>
                  }

                  <ol className={listClasses}>
                    {main.map((n, i) => {
                      const children = n.treeChildren.filter(itemFilter);

                      let itemClasses = [styles.item];

                      const hClass = styles['n' + children.length.toString()] || null;
                      if (hClass) {
                        itemClasses.push(hClass);
                      }

                      if (activeMobIndex != null) {
                        if (activeMobIndex !== i) {
                          itemClasses.push(styles.hide);
                        } else {
                          itemClasses.push(styles.open);
                        }
                      }

                      if (prepareMobIndex != null && prepareMobIndex === i) {
                        itemClasses.push(styles.willOpen);
                      }

                      const itemUrl = '/' + locale + '/' + n.link.slug + '/';

                      return (
                          <li className={itemClasses.join(' ')} key={i}>
                            <Button
                                className={styles.mobBtn}
                                href={children.length > 0 ? null : itemUrl}
                                onClick={children.length > 0 ? onMobIndexClick(i) : null}
                            >
                              {n.title}
                            </Button>
                            <Button className={styles.mobBackBtn} onClick={() => setActiveMobIndex(null)}>
                              {props.backButtonLabel}
                            </Button>

                            <span className={styles.inner}>
                              <a href={itemUrl}>{n.title}</a>

                              <ol className={styles.sub}>
                                {children.map((child, j) => (
                                    <li key={j} className={styles.item}>
                                      <a href={'/' + locale + '/' + child.link.slug + '/'}>{child.title}</a>
                                    </li>
                                ))}
                              </ol>
                            </span>
                          </li>
                      )
                    })}
                  </ol>
                </div>
            );
          }}
      />
  );
}
