import React from "react"
import styles from "./component.module.less";

import Button from "../../../button";

export default ({ className, mobActive, toolButtonUrl, toolButtonLabel }) => (
    <div className={`${styles.tool}${mobActive === true ? ' ' + styles.mobActive : ''}${className ? ' ' + className : ''}`} data-datocms-noindex={true}>
      <Button className={`${styles.item} ${styles.user}`} href={toolButtonUrl}>
        {toolButtonLabel}
      </Button>
    </div>
)
