import React from "react"

import HtmlHead from '../../components/html-head'
import Header from '../../components/header'
import Footer from '../../components/footer'

import styles from './component.module.less'

export default props => {
  const {header, content} = (props.children || {});

  const locale = props.locale || 'de';

  const cookieConsentRef = React.createRef();

  return (
      <>
        <HtmlHead
            locale={locale}
            localeLinks={props.localeLinks}
            title={props.title}
            robots={props.robots}
            seo={props.seo}
            cookieConsentRef={cookieConsentRef}
        />

        <div className={`${styles.layout}${props.className ? ' ' + props.className : ''}`}>
          {/* render default header if none given */}
          {!header && <Header locale={locale} />}

          {/* render given header */}
          {header && <>{header}</>}

          <main className={styles.main} ref={props.pageContentRef}>
            {/* render all children if no header and no content given */}
            {!header && !content && <>{props.children}</>}

            {/* render given content */}
            {content && <>{content}</>}
          </main>

          <Footer locale={locale} cookieConsentRef={cookieConsentRef} />
        </div>
      </>
  );
}
