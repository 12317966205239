import React from "react"
import {graphql, StaticQuery} from "gatsby"

import ContentContainer from '../content-container'

import Microdata from './components/microdata'

import styles from './component.module.less'

export default props => {

  const locale = props.locale || 'de';

  const isMinimal = props.minimal === true;

  const footerNavRenderer = (items) => (
      <>
        {(items || []).map((f, i) => {
          const isExternal = f.url && (f.url.startsWith('http:') || f.url.startsWith('https:'));

          const isCookieConsent = f.url && f.url === '?cc-settings';
          if (isCookieConsent) {
            return (
                <button
                    key={i}
                    onClick={() => {
                      if (!props.cookieConsentRef || !props.cookieConsentRef.current) return;
                      props.cookieConsentRef.current.setVisible();
                    }}
                >{f.title}</button>
            );
          }

          return (
            <a
                key={i}
                target={isExternal ? '_blank' : null}
                rel={isExternal ? 'noreferrer' : null}
                href={f.url ? f.url : '/' + locale + '/' + f.link.slug + '/'}
            >{f.title}</a>
          )
        })}
      </>
  );

  return (
      <StaticQuery
          query={graphql`
            query {
                allSite {
                    nodes {
                        buildTime
                    }
                }
            
                allDatoCmsNavigationFooterExtra {
                    nodes {
                        locale
                        area1Title
                        area1 {...nav_footer_extra_item}
                        area2Title
                        area2 {...nav_footer_extra_item}
                    }
                }
            
                allDatoCmsNavigationFooter(sort: {fields: position, order: ASC}) {
                    nodes {
                        ...nav_footer
                    }
                }
                
                allDatoCmsConfig {
                    nodes {
                        locale
                        companyName
                        companyAddress
                        companyAddressZip
                        companyAddressCity
                        companyPhone
                        companyFax
                        footerSeals {
                            url
                            image {
                                alt
                                fluid(imgixParams: {w: "132", auto: "compress", dpr: 1.5, q: 75}) {
                                    src
                                }
                            }
                        }
                    }
                }
            }
          `}
          render={data => {

            // const buildTime = new Date(Date.parse(data.allSite.nodes[0].buildTime));

            const config = data.allDatoCmsConfig.nodes.find(n => n.locale === locale) || {};

            const footerNav = data.allDatoCmsNavigationFooter.nodes.filter(n => {
              if (n.locale !== locale) return false;
              if (!n.title) return false;
              if (!n.url && (!n.link || !n.link.slug)) return false;
              if (isMinimal && !n.minimalEnabled) return false;
              return true;
            });

            const footerNavColCount = Math.round(footerNav.length / 2) + (footerNav.length > 0 && footerNav.length % 2 === 0 ? 1 : 0);

            const footerNav1 = footerNav.slice(0, footerNavColCount);
            const footerNav2 = footerNav.slice(footerNavColCount);

            return (
                <>
                  <footer className={styles.footer} data-datocms-noindex={true}>
                    <div className={styles.lower}>
                      <ContentContainer className={styles.container}>
                        <div className={`${styles.col} ${styles.logo}`} />
                        <nav className={styles.col}>
                          {footerNavRenderer(footerNav1)}
                          {footerNavRenderer(footerNav2)}
                        </nav>
                      </ContentContainer>
                    </div>
                  </footer>

                  <Microdata locale={locale} config={config} />
                </>
            );
          }}
      />
  );
}
