export default (locale) => {
  switch (locale) {
    case 'de':
      return '/de/suchergebnis/'
    case 'en':
      return '/en/searchresult/'
    case 'hu':
      return '/hu/searchresult/'
    case 'pl':
      return '/pl/searchresult/'
    case 'sk':
      return '/pl/searchresult/'
    default:
      throw new Error('locale ' + locale + ' not supported');
  }
}
